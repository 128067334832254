<template>
  <table class="w-100">
    <tr class="border-bottom">
      <th style="width: 1%; white-space: nowrap">SubChannel</th>
      <th style="width: 60px"></th>
      <th v-for="i in months" :key="i" class="text-center">{{ i }}</th>
    </tr>
    <template v-for="(info, id) in data">
      <tr :key="id + 'plan'">
        <td style="width: 1%; white-space: nowrap" rowspan="2">{{ info.name }}</td>
        <td class="text-right pr-2 font-weight-bold">plan</td>
        <td v-for="i in months" :key="i">
          <b-input
            v-model="info[i].plan_percent"
            :class="{ 'text-danger': totals[i]?.plan_percent != 100 }"
            type="number"
            min="0"
            @input="(v) => (info[i].plan_percent = round(v))"
          />
        </td>
      </tr>
      <tr :key="id + 'fact'" class="border-bottom">
        <td class="text-right pr-2 font-weight-bold">fact</td>
        <td v-for="i in months" :key="i">
          <b-input
            v-model.number="info[i].fact_percent"
            :class="{ 'text-danger': totals[i]?.fact_percent != 100 }"
            type="number"
            min="0"
            @input="(v) => (info[i].fact_percent = round(v))"
          />
        </td>
      </tr>
    </template>
    <tr>
      <td colspan="2" class="text-left pr-2 font-weight-bold">{{ $t('booking.total') }} plan</td>
      <td v-for="i in months" :key="i" :class="{ 'text-danger': totals[i]?.plan_percent != 100 }">
        {{ totals[i]?.plan_percent }}
      </td>
    </tr>
    <tr>
      <td colspan="2" class="text-left pr-2 font-weight-bold">{{ $t('booking.total') }} fact</td>
      <td v-for="i in months" :key="i" :class="{ 'text-danger': totals[i]?.fact_percent != 100 }">
        {{ totals[i]?.fact_percent }}
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
    subchannels: {
      type: Array,
      default: () => [],
    },
    valid: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      months: Array.from({ length: 12 }, (_, i) => i + 1),
      data: {},
    };
  },
  computed: {
    totals() {
      const res = {};
      for (const [_, info] of Object.entries(this.data)) {
        for (const [month, data] of Object.entries(info)) {
          if (res[month] == null) {
            res[month] = {
              plan_percent: 0,
              fact_percent: 0,
            };
          }
          res[month].plan_percent += parseFloat(data.plan_percent) || 0;
          res[month].fact_percent += parseFloat(data.fact_percent) || 0;

          res[month].plan_percent = this.round(res[month].plan_percent);
          res[month].fact_percent = this.round(res[month].fact_percent);
        }
      }
      return res;
    },
    isTableValid() {
      for (const v of Object.values(this.totals)) {
        if (v.plan_percent != 0 && v.plan_percent != 100) {
          return false;
        }
        if (v.fact_percent != 0 && v.fact_percent != 100) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    settings(v) {
      this.data = this.settingsToTable(v, this.subchannels);
    },
    subchannels() {
      this.subchannelsUpdated();
    },
    isTableValid: {
      handler(v) {
        this.$emit('update:valid', v);
      },
      immediate: true,
    },
  },
  mounted() {
    this.data = this.settingsToTable(this.settings, this.subchannels);
  },
  methods: {
    round(v) {
      return Math.round(v * 100) / 100;
    },
    settingsToTable(settings, subchannels) {
      const obj = {};
      for (const subchannel of subchannels) {
        const id = subchannel.id;
        if (obj[id] == null) {
          obj[id] = {};
        }
        for (const month of this.months) {
          obj[id].name = subchannel.name;
          const val = this.findValueInSettings(id, month);
          obj[id][month] = {
            fact_percent: val?.fact_percent ?? 0,
            plan_percent: val?.plan_percent ?? 0,
          };
        }
      }
      return obj;
    },
    findValueInSettings(id, month) {
      for (const set of this.settings) {
        if (set.sub_channel_id == id && set.month_id == month) {
          return {
            plan_percent: set.plan_percent,
            fact_percent: set.fact_percent,
          };
        }
      }
    },
    findValueInData(id, month) {
      for (const [sub_channel_id, settings] of Object.entries(this.data)) {
        const value = settings[month];
        if (sub_channel_id == id) {
          return {
            plan_percent: value.plan_percent,
            fact_percent: value.fact_percent,
          };
        }
      }
    },
    subchannelsUpdated() {
      const obj = {};
      for (const subchannel of this.subchannels) {
        const id = subchannel.id;
        if (obj[id] == null) {
          obj[id] = {};
        }
        for (const month of this.months) {
          obj[id].name = subchannel.name;
          const val = this.findValueInData(id, month);
          obj[id][month] = {
            fact_percent: val?.fact_percent ?? 0,
            plan_percent: val?.plan_percent ?? 0,
          };
        }
      }
      this.data = obj;
    },
    getNewSettings() {
      const res = [];
      for (const [id, subchannel_settings] of Object.entries(this.data)) {
        if (this.subchannels.find((x) => x.id == id))
          for (const month of this.months) {
            const value = subchannel_settings[month];
            const setting = {
              sub_channel_id: id,
              month_id: month,
              plan_percent: this.totals[month].plan_percent === 100 ? parseFloat(value.plan_percent) || 0 : 0,
              fact_percent: this.totals[month].fact_percent === 100 ? parseFloat(value.fact_percent) || 0 : 0,
            };
            res.push(setting);
          }
      }
      return res;
    },
  },
};
</script>
<style scoped></style>
